import React from 'react';
import { ManRadioButtonGroup, ManRadioButton, ManText } from '@craft-design-system/core-components-react';

const CustomTextDisplay = ({ value }) => {
  return (
    <div>
      {/* Title Section */}
      <ManText
        className="daily-mileage-text"
        variant="component-l"
        as="span"
        size="xl"
        style={{
          textAlign: 'left',
          display: 'block',
          padding: '10px',
        }}
      >
        AI | Automotive Intelligence
      </ManText>

      {/* Value Section */}
      <div>
      <ManText
  className="daily-mileage-text"
  variant="body-m"
  as="span"
  size="xl"
  style={{
    border: '2px solid black',
    padding: '10px',
    borderRadius: '8px',
    height: '72px',
    // width: '672px',
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'flex-start',
    alignItems: 'center',
  }}
>
          {value}
        </ManText>
      </div>
    </div>
  );
};

export default CustomTextDisplay;
