import { useGraphContext } from "../../GraphContext";
import pako from 'pako';
import { useResetContext} from '../../components/ResetContext';

const useHandleQuery = () => {
  const { graphData,updateGraphData } = useGraphContext();
  const {resetdata, updateresetdata} = useResetContext();

  const handleQuery = async ({ selectedValues }) => {
    
    try {
      // Log the selected values for debugging purposes
      let baseURL;
      if (window.location.hostname === 'simbaanalytics-dev.rio.cloud') {
        baseURL = 'https://26ovsbph5oz6xpn2fgscmr5x5i0socao.lambda-url.eu-west-1.on.aws';
      } else if (window.location.hostname === 'bev-dev.simbaanalytics-dev.rio.cloud') {
        baseURL = 'https://u5algw5g5a2uwmhamqhdtyuhoe0xelae.lambda-url.eu-west-1.on.aws';
      } else if (window.location.hostname === 'bev-prod.simbaanalytics-dev.rio.cloud') {
        baseURL = 'https://5hflt4lvmiwp4naousasyh4jpy0zhniw.lambda-url.eu-west-1.on.aws';
      }
      else {
        baseURL = 'http://localhost:8080';
      }
      const valuesToSend = { ...selectedValues, resetdata };



      // Make a POST request to the specified endpoint
      const response = await fetch(`${baseURL}/selected-option`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(valuesToSend),
      });
      
      if (!response.ok) {
        updateGraphData({'message':'Unable to update graph data: Please try again after some time.'});
        throw new Error(`HTTP error! status: ${response}`);
      }

      console.log('response',response)
      
      // Check the Content-Encoding header of the response
      const contentEncoding = response.headers.get('Content-Encoding');
    
      
      let result;
      if (contentEncoding === 'gzip') {
        // If the response is gzip-compressed, decompress it
        const compressedData = await response.arrayBuffer();
        const decompressedData = pako.ungzip(new Uint8Array(compressedData), { to: 'string' });
        result = JSON.parse(decompressedData);
      } else {
        // If the response isn't gzip-compressed, parse it as JSON directly
        result = await response.json();
      }
      
      updateGraphData(result);

      //console.log('ty3vdev',graphData)

      // Log the result for debugging purposes
      
    } catch (error) {
      // Log any errors that occur during the process
      console.error('Error:', error.message);
    }
  };

  // //console.log('graph_data',graphData);

  return handleQuery;
};

export default useHandleQuery;
