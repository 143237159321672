import React, { useState, useEffect } from 'react';
import { ManText } from "@craft-design-system/core-components-react";
import { useFilterContext } from './FilterContext';
import { useDeselectContext } from './DeselectContext';

const Selected_filters = () => {
  const { filterdata, updateFilterData } = useFilterContext();
  const { updatedeselectdata } = useDeselectContext();
  const [storedFilterData, setStoredFilterData] = useState(null);

  

  // Function to unselect option and update filter data
  const unselectOption = (dropdown) => {
    
    // Create a new copy of filterdata and remove the dropdown entry
    const updatedFilterData = { ...filterdata };
    delete updatedFilterData[dropdown];
  
    // Update the filter data state
    updateFilterData(updatedFilterData);
  
    updatedeselectdata(null); // Set to null first, then trigger the actual value

    setTimeout(() => {
      // Now update deselectdata after a slight delay to ensure state is updated
      updatedeselectdata(dropdown);
    }, 0);
  
  };
    console.log('filterdata',filterdata)
  
    useEffect(() => {
      // Update storedFilterData when button_clicked is true
      if (filterdata && filterdata.hasOwnProperty('button_clicked') && filterdata['button_clicked'] === true) {
        setStoredFilterData(filterdata);
      }
    }, [filterdata]);

    const hasButtonSelected = filterdata && filterdata.hasOwnProperty('button_clicked') && filterdata['button_clicked'] === true;

    return (
      <div style={{ background: 'white', padding: '10px', borderRadius: '8px' }}>
        <ManText className="filter-title" variant={"heading-l"} as={"span"} style={{ marginLeft: '11px' }}>
          Filter criteria
        </ManText>

        {storedFilterData ? (
          <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '10px' }}>
            {Object.entries(storedFilterData).map(([key, value]) => (
              value && value.length > 0 && (
                <div key={key} style={{ margin: '10px', padding: '10px', background: '#F8F8F8', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                  <button
                    // onClick={() => unselectOption(key)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                  >
                    <ManText className="filter-title" variant={"heading-s"} as={"span"} style={{ marginRight: '5px' }}>
  {key}: {Array.isArray(value) ? value.join(', ') :value}
</ManText>

                    <span style={{
                      fontWeight: 'bold',
                      marginLeft: '5px',
                      fontSize: '20px',
                      color: '#FF0000',
                      cursor: 'pointer'
                    }}>
                    </span>
                  </button>
                </div>
              )
            ))}
          </div>
        ) : (
          <ManText variant={"heading-s"} as={"span"} style={{ marginLeft: '11px', color: '#5B6F85' }}>
            No filters applied.
          </ManText>
        )}
      </div>
    );
  };

export default Selected_filters;
