import {
    Installation_energy,
    No_of_packs,
    All_time_energythroughput,
    MAN_part_no,
    Software_stage,
    DOD_config,
    SOC,
    Depth_of_discharge,
    Battery_temp,
    Calendric_aging,
    Efficiency,
    A_cal_val,
    Cyclic_aging,
    A_cyc_val,
    SOH

}
from './battery_analysis_kpi'
import { useGraphContext } from '../GraphContext';
import { Card } from 'react-bootstrap';


const BatteryAnalysis = () => {
    const { graphData } = useGraphContext();

    const {
        installed_energy,
        no_of_packs,
        All_time_energy_throughput,
        man_part_no,
        software_stage,
        dod_Config,
        soc,
        depth_of_discharge,
        vin_short,
        battery_temp,
        efficiency,
        calendric_aging_matrix,
        a_cal_val,
        cyc_aging_matrix,
        a_cyc_val,
        soh,
        batterytemp_avg_statement,
        





    }=graphData;

    //console.log('graphDataecfefv',graphData)






  return (
    <div>
    <div className="card-info">
    <Installation_energy value={installed_energy}/>
    <No_of_packs value={no_of_packs}/>
    <All_time_energythroughput value={ All_time_energy_throughput}/>
    <MAN_part_no value={man_part_no}/>
    <Software_stage value={software_stage}/>
    <DOD_config value={dod_Config}/>
    </div>
    <div className="card-info1">
    <Card className='firstcard'>
    <SOC value={soc}/>
    </Card>
    <Card className='firstcard'>
    <SOH value={soh}/>
    </Card>
    <Card className='firstcard'>
    <Depth_of_discharge value={{depth_of_discharge,vin_short}} />
    </Card>

    <Card className='firstcard'>
    <Battery_temp value={{battery_temp,vin_short,batterytemp_avg_statement}}/>
    </Card>
    <Card className='firstcard'>
    <Calendric_aging value={calendric_aging_matrix}/>
    </Card>
    <Card className='firstcard'>
    <Cyclic_aging value={cyc_aging_matrix}/>
    </Card>
    <Card className='firstcard'>
    <A_cal_val value={a_cal_val}/>
    </Card>
    <Card className='firstcard'>
    <A_cyc_val value={a_cyc_val}/>
    </Card> 

    <Card className='firstcard'>
    <Efficiency value={efficiency}/>
    </Card>

    </div>
    </div>

    
  )
}

export default BatteryAnalysis;