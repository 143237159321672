import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Graphs from '../components/Graphs';
import MAN_info from '../components/MAN_info';
import Footer from '../components/Footer';
import NewFeaturesModal from './New_feature';
const Mainscreen = ({props}) => {

  const handleClick = (event) => {
    const element = event.target;
    const clickData = {
        eventType: "click",
        elementId: element.id || "no-id",
        elementClass: element.className || "no-class",
        elementType: element.tagName, // Captures the HTML tag (e.g., BUTTON, DIV)
        timestamp: new Date().toISOString(),
        userAgent: navigator.userAgent,
    };
    // sendToFirehose(clickData);
    console.log(clickData);
};
document.addEventListener('click', handleClick);


return (
    <div className='app'>
      <NewFeaturesModal />
      <Header />
      <MAN_info />
    <div className='main-content'>
      <Sidebar props ={{props}} />
      {/* <Graphs /> */}
    </div>
      
    </div>

);
};

export default Mainscreen;