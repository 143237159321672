import React , {useEffect} from 'react';
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Spinner } from 'react-bootstrap'; // Import Spinner from react-bootstrap
import Mainscreen from './screens/main_screen';
import Shimmer_graph from './components/shimmer_graph';
import { initializeRUM } from './awsRUM';

function App() {
  const { accounts, inProgress } = useMsal();
  const account = accounts[0] || {};
  const isAuthenticated = accounts.length > 0;

  const username = account.username;
  console.log("Username: ", username);
  const idToken = account.idToken;

  useEffect(() => {
    const hostname = window.location.hostname;
    const port = window.location.port;
  
    if (
      (hostname !== 'localhost' || port !== '3000') &&
      hostname !== 'bev-dev.simbaanalytics-dev.rio.cloud'
    ) {
      console.log('Initializing AWS RUM');
      initializeRUM();
    }
  }, []);
  

  // console.log("Username: ", username);
  // console.log("ID Token: ", idToken);


  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <div>
        {isAuthenticated ? (
          <Mainscreen props={{ username }} /> // Pass the username as a prop
        ) : (
          <h1>Please sign in</h1>
        )}
      </div>
    </MsalAuthenticationTemplate>
  );
}


export default App;