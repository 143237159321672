import  { useState, useEffect } from 'react';
import { ManText, ManSelect, ManSelectItem,ManInputText} from "@craft-design-system/core-components-react";
import Shimmer from "./Shimmer";
import useDropdownData from '../utils/dropdown_data_loading/useDropdownData';
import useHandleQuery from "../utils/dropdown_data_loading/useHandleQuery";
import useDynamicFiltering from '../utils/dropdown_data_loading/useDynamicFiltering';
import { Collapse } from 'reactstrap'; // or react-bootstrap
import filters from '../assets/images/filters.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import 'react-tooltip/dist/react-tooltip.css'

import { ManHelperText } from '@craft-design-system/core-components-react';
import { useFilterContext } from './FilterContext';
import { useDynamicContext } from './DynamicContext';
import { useResetContext } from './ResetContext';
import { useDeselectContext } from './DeselectContext';
import { useGraphContext } from '../GraphContext';
import FilterSidebar from './FilterSidebar';
const Bus_filters= (props) => {

  console.log('bus',props)


  const [selectedValues, setSelectedValues] = useState({});
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownData, setDropdownStates] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showTextData, setShowTextData] = useState(false);
  const {resetdata, updateresetdata,clearGraphData} = useResetContext();
  const {deselectdata, updatedeselectdata} = useDeselectContext();

  const {filterdata, updateFilterData} = useFilterContext();
  const {dynamicdata, updatedynamicdata} = useDynamicContext();
  const { graphData,updateGraphData } = useGraphContext();

  // handlegraphclick = YourComponent();

  const { dynamicFiltering, dropdownInfo } = useDynamicFiltering();
  let {dropdowndata,fetchData,isRefreshing} = useDropdownData();

  

  const handleSelectChange = (selectedOptions, columnName) => {
    setSelectedValues(prevValues => {
      const newValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
      const updatedValues = {
        ...prevValues,
        [columnName]: newValues,
        button_clicked: false,
        username:props.username
      };
      // Only update filter data if something actually changed
      updateFilterData(updatedValues);
      
    
      return updatedValues;
    });
    setIsInitialLoad(false);
};

useEffect(() => {
  console.log("Updated selectedValues: ", selectedValues);


  console.log("Updated filterdata: ", graphData);
}, [selectedValues]);



  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: 'red',  // Change this to your desired color
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'grey' : state.isFocused ? '#EAEDF3' : null,
      color: state.isSelected ? 'white' : 'black',

    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '1px',
      borderWidth: '1px',
      borderColor: '#5B6F85',
      cursor: 'pointer',
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        // display: 'inline-block',
        // margin: '2px',
      };
    },
    valueContainer: (styles, { data }) => {
      return {
        ...styles,
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
      };
    },
  };


  useEffect(() => {
    setDropdownStates(dropdowndata);
    setSelectedValues(null);
  }, [dropdowndata]);

  useEffect(() => {
    if (deselectdata !== null) {
  
      // Update the state to clear the selected values for the specific dropdown
      setSelectedValues((prevSelectedValues) => {
        const updatedSelectedValues = { ...prevSelectedValues };
        updatedSelectedValues[deselectdata] = []; // Clear options for the respective dropdown
        return updatedSelectedValues;
      });
    }
  }, [deselectdata]);
  
  const handleValuesChange = (minValue, maxValue) => {

    setSelectedValues(prevValues => {
      const updatedValues = {
        ...prevValues,
        'Total mileage [km]': [minValue,maxValue],
      };
      updateFilterData(updatedValues);
      return updatedValues;

    });

  };




  useEffect(() => {
    setDropdownStates(dynamicdata);
    // setSelectedValues(null);
  }, [dynamicdata]);

  useEffect(() => {
    console.log('resetdata_truck',resetdata); 
    if (resetdata === 'Bus1') {
      fetchData();
      setDropdownStates(dropdowndata);
      setSelectedValues(null);
    }
  }, [resetdata]);

  if (!dropdownData || isRefreshing ) return <Shimmer />;

  return (
    <FilterSidebar
        dropdownData={dropdownData}
        selectedValues={selectedValues}
        handleSelectChange={handleSelectChange}
        customStyles={customStyles}
        onvaluesChange={handleValuesChange}
      />
  );
};

export default Bus_filters;
